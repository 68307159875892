#about-me-container {
  display: flex;
  flex-direction: row;
  padding: 5vh 0 5vh 0;
}

#about-me-content {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

#about-me-description {
  display: flex;
  flex-direction: column;
  margin-bottom: 3vh;
}

#about-me-photo {
  display: flex;
}

#about-me-title {
  margin-bottom: 3vh;
}

img.rounded {
  object-fit: cover;
  object-position: 0 0;
  border-radius: 50%;
  height: 25vw;
  width: 25vw;
  max-width: 160px;
  max-height: 160px;
}
