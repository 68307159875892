.App {
  display: flex;
}

.nav {
}

.nav a {
  padding: 10px 10px 10px 10px;
  font-size: 24px;
}
