.blog-post p {
  margin-top: 16px;
}
.blog-post-item {
  display: block;
  background-color: #EEEEEE;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.blog-post-item a {
  font-size: 20px;
}

code {
  font: 400 90%/1 Roboto Mono,monospace;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.notebook-buttons {
  display: inline-block;
  margin-left: 3px;
  width: auto;
  margin-top: 30px;
}

.notebook-buttons a {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
  color: #202124;
  padding: 24px 24px;
  transition: box-shadow 0.2s;
  height: 50px;
}

.notebook-buttons td {
  padding-left: 0px;
  padding-right: 20px;
  padding: 7px 8px 8px;
}

.notebook-buttons td > a > img {
  margin-right: 8px;
  height: 100%;
  vertical-align: middle;
}

.notebook-buttons td {
  padding-left: 0;
  padding-right: 20px;
}

