img.research {
  object-fit: cover;
  height: 20vw;
  width: 20vw;
  max-height: 120px;
  max-width: 120px;
  background-color: white;
  border: 2px black solid;
}

.research-item-container {
  display: flex;
  padding: 3vh 0px 3vh 0px;
}

.research-item-project-link {
  font-weight: normal;
}

.research-item-project-link {
  font-weight: normal;
}

.research-item-project-link:hover {
  font-weight: normal;
}

.research-item-text {
  display: flex;
  flex-direction: column;
  padding: 0px 12px 0px 12px;
  width: 60vw;
}

.research-item-venue {
  font-style: italic;
}

.research-item-description-divider {
  margin-top: 6px;
}
