img.work {
  object-fit: cover;
  height: 10vw;
  width: 10vw;
  max-height: 60px;
  max-width: 60px;
  border-radius: 50%;
}

.work-item-container {
  display: flex;
  padding: 16px 0px 16px 0px;
}

.work-item-text {
  display: flex;
  flex-direction: column;
  padding: 0px 16px 0px 16px;
}

.work-item-time {
  color: #7f7f7f;
}

.work-item-description-divider {
  margin-top: 6px;
}
