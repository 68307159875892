#icon-container {
  display: flex;
}

#icon-cv {
  background-color: #cbcbcb;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

#icon-cv-text {
  color: #ffffff;
  font-size: 20px;
  font-weight: normal;
}
