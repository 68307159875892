body {
  display: flex;
  margin: 0;
  padding: 0;
  font-family: "Google Sans", Verdana, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

#root {
  width: 100%;
}

p {
  text-align: left;
  margin: 0;
}

h1 {
    color: inherit;
    font: 400 32px/40px Google Sans,Noto Sans,Noto Sans JP,Noto Sans KR,Noto Naskh Arabic,Noto Sans Thai,Noto Sans Hebrew,Noto Sans Bengali,sans-serif;
    letter-spacing: 0;
    margin: 48px 0 24px;
}

h2 {
  color: #000;
  display: flex;
  font-size: 22px;
  margin: 48px 0 24px;
  font-weight: normal;
}

h3 {
  color: #5a5a5a;
  display: flex;
  font-size: 18px;
  margin: 2vh 0 0 0;
  padding: 0;
  font-weight: normal;
}

h4 {
  color: #000;
  display: flex;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.gray-background {
  background-color: #f2f7f8;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  color: #1772d0;
  font-weight: 550;
  text-decoration: none;
}

a:hover {
  color: #f09228;
  font-weight: 550;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}
